import * as React from "react"
import { Link } from "../components"
import BlogPageLayout from "../components/layouts/BlogPageLayout"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage = ({location, pageContext}) => {
  return (
    <BlogPageLayout location={location} pageContext={pageContext} isPost={false}>
      Sorry 😔, we couldn’t find what you were looking for. <br/>
      <Link to="/">Go home</Link>.
    </BlogPageLayout>
    
  )
}

export default NotFoundPage